<template>
	<div class="navmenuwrapper">
		<div class="navmenu">
		<div  :class="{ 'menu': true, 'menuselect': item.isSelect, 'color000': color000 }"  ontouchmove 

			v-for="item in navlist" :key="item.id">
			{{ item.title }}
			<div class="submenu">
				<div class="subitem" v-for="i in item.children" @click="toRoute(i)">
					<span :class="{ 'subtitle': true, 'subselected': i.isSelect }">{{ i.title }}</span>
				</div>
			</div>
		</div>
	</div>
	</div>
	
</template>

<script>

// mouseout: 鼠标离开事件
// mouseleave: 鼠标离开事件
// 区别:
// 不论鼠标指针离开被选元素还是任何子元素，都会触发 mouseout 事件。
// 只有在鼠标指针离开被选元素时，才会触发 mouseleave 事件。
// mouseover: 鼠标移入事件
// mouseenter: 鼠标移入事件

import { detail } from '@/api/website/tradesystem.js'

export default {
	name: 'NavMenu',

	data() {
		return {
			collectUrl:'',
			color000: false,
			banner: {},
			navlist: [
				{
					title: '土拍聚焦',
					isSelect: false,
					children: [
						{
							title: '土拍头条',
							url: '/list/toutiao',
							isSelect: false,
						},
						{
							title: '土拍日程',
							url: '/listcalendar/calendar'
						},
						{
							title: '土拍直播',
							url: '/list/zhibo'
						},
						{
							title: '土拍公告',
							url: '/list/gonggao/1/6'
						},
						{
							title: '网上交易系统',
							url: ''
						}
					]
				},
				{
					title: '土拍数据',
					url: '',
					children: [
						{
							title: '土拍政策',
							url: '/list/zhengce'
						},
						{
							title: '航拍图',
							url: '/list/hangpai'
						},
						{
							title: '土地推介资料',
							url: '/list/ziliao'
						}

					]
				}, {
					title: '土拍并购',
					url: '',
					children: [
						{
							title: '招商推介',
							url: '/list/zhaoshang'
						},
						{
							title: '居间代理',
							url: '/list/jujian'
						}

					]
				}
				, {
					title: '城市展厅',
					url: '',
					children: [
						{
							title: '2025线上展厅',
							url: '/collect/indexpage'
						},
						{
							title: '历届中国城市土地展',
							url: '/list/tudizhan'
						},
						{
							title: '高端峰会',
							url: '/list/fenghui'
						}

					]
				}
			]
		};
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {
			handler(nv) {
				this.init()
			},
			deep: true,
			immediate: true
		}
	},

	mounted() {
	
		this.init()
	},




	methods: {

		out(event) {
      event.target.style.color = '#000';
			event.target.style.fontSize = '14px';
			event.target.style.background = '#fff';
		},

		over(event){
			event.target.style.color = '#00A3E4';
			event.target.style.fontSize = '14px';
			event.target.style.background =  '#E6F6FD';
		},
		//跳到外链
		towindowurl() {
			window.location.href = this.banner.link;
		},







		init() {
			detail({ site_id: this.site.id }).then(res => {
				this.banner = res.data
			})


			//获取当前的路径
			const path = this.$route.fullPath;

			console.log('this.$route', this.$route)
			//处理当前的列表的选中状态
			let newlist = []
			newlist = this.navlist.map(item => {
				item.isSelect = false
				item.children.map(i => {
					console.log('111111',i)

					let url = ''
					if(i.url=== '/collect/indexpage'){
						url =  i.url
				  		
					}else{
						 url = '/' + this.site.path + i.url
					}
					

					console.log('url',url,'path',path)

					if (url === path) {
						item.isSelect = true
						i.isSelect = true
					} else {
						if (this.$route.meta.navselected !== undefined) {

							if (i.url.replace(/\/\d+\/\d+$/, "") === this.$route.meta.navselected) {
								item.isSelect = true
								i.isSelect = true
							}else{
								// item.isSelect = false
								i.isSelect = false
							}

						} else {
							i.isSelect = false
						}


					}
					return i
				})
				return item;
			})

			this.navlist = newlist;



		},
		//跳转
		toRoute(route) {
			console.log(route)
			if (route.title === '网上交易系统') {
				this.towindowurl()
			} else {
				if(route.url === '/collect/indexpage'){
					this.$router.push(route.url)
				}else{
					const tourl = '/' + this.site.path + route.url
					this.$router.push(tourl)
				}
				
			}

		}


	},
};
</script>

<style lang="scss" scoped>
.navmenuwrapper{
	width:100%;
	background:#fff;
}

.navmenu {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	background: #fff;
}

.menu {
	width: 300px;
	height: 56px;
	cursor: pointer;
	background: #FFFFFF;
	font-size: 18px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	line-height: 56px;
	text-align: center;
	position: relative;
	color: #000;
	box-sizing: border-box;



	.submenu {
		position: absolute;
		left: 0;
		display: none;
		background: #FFFFFF;
		border-top:solid 1px #F0F3F8;
		flex-direction: row;

		line-height: 40px;
		height: 40px;

	color: #000;

		.subitem {
			line-height: 40px;
			height: 40px;
			display: inline-block;
			float: left;

		}

	}

	


}

.menu:hover {
	background: #E6F6FD;
	width: 300px;
	// margin-right:1px;
	// margin-left:1px;
	border-left:1px solid   #E6F6FD;
	border-right:1px solid   #E6F6FD;
	height: 56px;
	color: #00A3E4;
	.submenu {
		display: block;
		color: #000;

		.subitem {
			background: #fff;

			.subtitle {
				height: 20px;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
				display: inline-block;
				padding: 0 19px;
				border-right: 2px solid #f2f2f2;
				background: #fff;

				&:hover {
					font-weight: 600;
					color: #E77817;
				}

			}

			.subselected {
				font-weight: 600;
				color: #E77817;
			}

		}

		.subitem:last-child {
			
			.subtitle {
				border-right: none;
			}

		}


	}
}

.menuselect {
	background: #E6F6FD;
	width: 300px;
	height: 56px;
	color: #00A3E4;

}


.menu:first-child {
	.submenu {
		width: 560px;
	}
}

.menu:nth-child(2) {
	.submenu {
		width: 330px;
	}
}

.menu:nth-child(4) {
	.submenu {
		width: 450px;
	}
}


.color000 {
	color: #000;
}
</style>