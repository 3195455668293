import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'



export function sitelist() {
	return loginRequest({
		url: '/frontend/website/site/list',
		method: 'post'
		
	})
}



//
export function contact() {
	return loginRequest({
		url: '/frontend/website/site/contact',
		method: 'post'
	})
}




//获取站点底部信息
export function siteinfo(params) {
	return loginRequest({
		url: '/frontend/website/site/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//获取qrcode
export function qrcodeinfo(params) {
	return loginRequest({
		url: '/frontend/website/qr/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}




