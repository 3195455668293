<template>
	<div class="footerbox">
		<div :class="{ 'qrbox': true, 'qrbox156': showqr }">
			<div class="rightbox">
				<div v-if="innerqr1" class="innerqr1">
					<div class="bluebox">
						<img :src="qrcode[0].pic" alt="">
					</div>
					<div class="scan">扫描二维码</div>
					<div class="scantext">关注土拍公众号</div>
				</div>
				<div v-if="innerqr2" class="innerqr2">
					<div class="bluebox">
						<img :src="qrcode[1].pic" alt="">
					</div>
					<div class="scan">微信扫一扫</div>
					<div class="scantext">土拍网小程序</div>
				</div>
				<div v-if="innerqr3" class="innerqr3">
					<div class="bluebox">
						<img :src="qrcode[2].pic" alt="">
					</div>
					<div class="scan">微信扫一扫</div>
					<div class="scantext">土拍并购小程序</div>
				</div>
			</div>

			<div class="innericon">
				<div class="iconitem" v-if="oldsite" @click="clickguanwang" @mouseover="onHover0()" @mouseleave="enter0()">
					<img :src="guanwang" alt="">
					<div class="text">{{ oldsitename }}</div>
				</div>

				<div class="iconitem" @mouseover="onHover1()" @mouseleave="enter1()">
					<img :src="gongzhonghao" alt="">
					<div class="text">公众号</div>
				</div>
				<div class="iconitem" @mouseover="onHover2()" @mouseleave="enter2()">
					<img :src="xiaochengxu" alt="">
					<div class="text">小程序</div>
				</div>
				<div class="iconitem" @mouseover="onHover4()" @mouseleave="enter4()">
					<img :src="tupaibingo" alt="">
					<div class="text">土拍并购</div>
				</div>
				<div class="iconitem" v-if="showButton" @click="scrollToTop" @mouseover="onHover3()" @mouseleave="enter3()">
					<img :src="dingbu" alt="">
					<div class="text">顶部</div>
				</div>
			</div>
		</div>

		<div class="footer">

			<div class="ft">
				<div class="ft1">
					<div class="fttitle">
						网站导航
					</div>
					<div class="ft1con">
						<div class="ft1item" v-for="(info,index) in navinfo" @click="toNav(info,index)">{{ info.title }}</div>
					</div>
				</div>
				<div class="ft2">
					<div class="fttitle">
						联系地址
					</div>
					<div class="ft2con">
						<div>公司地址：{{ info.address }}</div>
						<div>联系电话：{{ info.phone }}</div>
						<div>法律顾问：{{ info.adviser }}</div>

					</div>

				</div>
				<div class="ft3">
					<div class="fttitle">
						订阅最新资讯
					</div>
					<div class="ft3con">
						<div class="wechart itemqr">
							<img :src="qrcode[0].pic" alt="">
							<div>{{ qrcode[0].name }}</div>
						</div>
						<div class="mini itemqr">
							<img :src="qrcode[1].pic" alt="">
							<div>{{ qrcode[1].name }}</div>
						</div>
					</div>

				</div>
			</div>
			<div class="fb">
				<span>
					{{ info.copyright[0] }}
				</span>
				<span  @click="togongxin" >
					{{ info.copyright[1] }}
				</span>
				<span  @click="toicp">
					{{ info.copyright[2] }}
				</span>
		
			</div>
		</div>
	</div>
</template>

<script>
import { siteinfo, qrcodeinfo } from '@/api/website/site.js';
export default {
	name: 'PcWebsiteFooter',

	data() {
		return {
			showButton: false,
			innerqr1: false,
			innerqr2: false,
			innerqr3: false,
			showqr: false,
			guanwang:require("@/assets/images/guanwang1.png"),
			gongzhonghao: require("@/assets/images/gzh2.png"),
			xiaochengxu: require("@/assets/images/xcx2.png"),
			tupaibingo: require("@/assets/images/bingoicon2.png"),
			dingbu: require('@/assets/images/db2.png'),
			qrcode: [{
				pic: ''
			}, {
				pic: ''
			}],
			oldsite:'',
			oldsitename:'',
		
			info: {
				copyright:[
					'',''
				]
			},
			navinfo: [
				{
					title: '会员中心',
					route: '',
					url: 'member/info'
				},
				{
					title: '关于我们',
					route: '/about/intro',
					url: ''
				},
				{
					title: '加盟土拍',
					route: '/jointupai',
					url: ''
				},
				{
					title: '意见反馈',
					route: '/feedback',
					url: ''
				},
				{
					title: '联系我们',
					route: '/contact',
					url: ''
				},

			]
		};
	},



	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler(ov, nv) {
				this.getSiteinfo()
			},
			deep: true
		}
	},

	mounted() {
		window.addEventListener('scroll', this.handleScroll);

		this.oldsite =  process.env.VUE_APP_OLDSITE
		this.oldsitename = process.env.VUE_APP_OLDSITENAME
		this.getSiteinfo()
		this.getqrcodeinfo()


	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},

	methods: {
		togongxin(){
			window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
		},

		toicp(){	
			let routeData = this.$router.resolve({ path: '/icp' }); 
       window.open(routeData.href, '_blank');
		},
		handleScroll() {
			this.showButton = window.pageYOffset > 0;
			this.dingbu = require('@/assets/images/db2.png')

		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});

		},
		onHover0() {
			this.guanwang = require('@/assets/images/guanwang2.png')

		},
		enter0() {
			this.guanwang = require('@/assets/images/guanwang1.png')
		
		},
		clickguanwang(){
			window.open(this.oldsite)
		},
		

		onHover1() {
			this.gongzhonghao = require('@/assets/images/gzh1.png')
			this.innerqr1 = true;
			this.innerqr2 = false;
			this.innerqr3 = false;
			this.showqr = true
		},
		enter1() {
			this.gongzhonghao = require('@/assets/images/gzh2.png')
			this.innerqr1 = false;
			this.innerqr2 = false;
			this.innerqr3 = false;
			this.showqr = false
		},
		onHover2() {
			this.xiaochengxu = require('@/assets/images/xcx1.png')
			this.innerqr1 = false;
			this.innerqr2 = true;
			this.innerqr3 = false;
			this.showqr = true
		},
		enter2() {
			this.xiaochengxu = require('@/assets/images/xcx2.png')
			this.innerqr1 = false;
			this.innerqr2 = false;
			this.innerqr3 = false;
			this.showqr = false
		},
		onHover4() {
			this.tupaibingo = require('@/assets/images/bingoicon1.png')
			// this.innerqr1 = false;
			// this.innerqr2 = false;
			this.innerqr3 = true;
			this.showqr = true
		},
		enter4() {
			this.tupaibingo = require('@/assets/images/bingoicon2.png')
			// this.innerqr1 = false;
			// this.innerqr2 = false;
			this.innerqr3 = false;
			this.showqr = false
		},

		onHover3() {
			this.dingbu = require('@/assets/images/db1.png')
		},
		enter3() {
			this.dingbu = require('@/assets/images/db2.png')
		},


		toNav(item,index) {
			if (item.url) {
				const usercenter = process.env.VUE_APP_LOGIN_BASIC
				if(index === 0){
					window.open(usercenter + item.url)
				}else{
					window.location.href = usercenter + item.url
				}
			
			} else {
				const currentpath = this.$route.fullPath
				const route = '/' + this.site.path + item.route
				if (currentpath !== route) {
					this.$router.push(route)
				}
			}
		},

		getqrcodeinfo() {
			qrcodeinfo().then(res => {
				this.qrcode = res.data
			})
		},

		getSiteinfo() {
			const params = { id: this.site.id }
			siteinfo(params).then(res => {
				this.info = res.data
			})
		}

	},
};
</script>

<style lang="scss" scoped>
.qrbox {
	width: 56px;
	background: #ffffff01;
	z-index: 15;
	position: fixed;
	bottom: 200px;
	right: 10px;
	display: flex;

	.innerqr1,
	.innerqr2,
	.innerqr3
	{
		margin-top: 0px;
		width: 170px;
		height: 220px;
		display: flex;
		flex-direction: column;
		background: #fff;
		

		.bluebox {
			margin-top: 20px;
			margin-left: 18px;
			background: url(~@/assets/images/bluebox.jpg) top center no-repeat;
			background-size: 100%;
			width: 134px;
			height: 134px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.scan{
			margin-top:10px;
			font-size:14px;
			line-height: 18px;
		}
		.scantext{
			font-size:18px;
			line-height: 30px;
			font-weight: bold;
		}


		img {
			width: 100px;
			height: 100px;
		}

	}

	.innerqr2 {
		margin-top: 75px;
	}

	.innerqr3 {
		margin-top: 145px;
	}

	.rightbox {
		background: rgba(255, 255, 255, 0);
	}

	.innericon {

		height: 368px;
		width: 56px;
		background: #ffffff00;

		.iconitem {
			border-bottom: solid 1px #dedede;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 56px;
			height: 75px;
			background: #FFFFFF;
			cursor: pointer;

			.text {
				color: #000;
				margin-top:10px;

			}

			img {
				height: 32px;
				width: 32px;
			}
		}

		.iconitem:last-child {
			border-bottom: none;
		}

		.iconitem:hover {
			background: #00A3E4;

			.text {
				color: #fff;
			}

		}
	}


}

.qrbox156 {
	width: 226px;
}


.fb {
	height: 17px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 17px;
	cursor: pointer;
}

	.footerbox {
	margin-top: 40px;
	height: 290px;
	width: 100%;
	background: url('/pic/bottom.png') no-repeat center center;
	background-size: cover;
	/**cover作用？**/
	// background-position: 50% 50%;

	.footer {
		margin: 0 auto;
		width: 1200px;
		height: 290px;

		.ft {
			display: flex;
			height: 260px;
			width: 100%;
			display: flex;
		}


	}
}

.fttitle {
	height: 80px;
	font-size: 18px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 80px;
	text-align: left;
	margin-top: 10px;
}

.ft1 {
	width: 200px;
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 25px;
}

.ft1con {
	width: 56px;
	height: 150px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 30px;

	.ft1item {
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}
}


.ft3 {
	width: 260px;
	height: 100%;

	.ft3con {
		display: flex;
		justify-content: space-between;

		.itemqr {

			img {
				width: 100px;
				height: 100px;
				background: #fff;
			}

			div {
				height: 40px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;

			}
		}

	}
}

.ft2 {
	width: 280px;
	height: 100%;
	flex: 1;

	.ft2con {

		width: 100%;
		text-align: left;

		div {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			height: 30px;
			line-height: 30px;
			font-weight: 400;
			color: #FFFFFF;
			cursor: pointer;
		}

	}

}
</style>